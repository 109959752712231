<template>
<Modal v-model="showModal" :mask-closable="false" width="500px">
  <div slot="header">批量导入单灯信息</div>
  <div class="import-container">
    <Form ref="form" :label-width="100" class="import-form">
      <FormItem prop="lng" label="选择文件">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :disabled="loading" />
          </div>
        </div>
      </FormItem>
    </Form>
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button size="default" type="success" @click="ok" style="margin: 0 5px;">{{showLang('import.batch')}}</Button>
  </div>
</Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampImportNew',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  mounted: function(){
  },
  methods: {
    ok: async function () {
      if(this.$refs.fileInput.value == ''){
        this.$Message.warning(this.showLang('com.import.file.select'));
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.fileInput.files[0]);
      this.$axios.request({
        url: `device/light/ImportNonePoleLightTemplate`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
        if(res.code == 0){
          this.showModal = false;
          this.$emit('saved', {});
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}

.import-container{
  /* border: solid 1px red; */
  /* height: 100%; */
  display: flex;
}
.import-form{
  /* border: solid 1px red; */
  /* width: 350px; */
  /* flex: none; */
}
.import-data{
  width: 500px;
  flex: auto;
  margin-left: 35px;
}
.logo-upload{
  width: 100%;
  height:40px;
  border:solid 1px #dcdee2;
  cursor: pointer;
}
.logo-upload input{
  /* visibility: hidden; */
  width: 100%;
}
.logo-upload button{
  /* visibility: hidden; */
  width: 100%;
  height: 40px;
}
</style>